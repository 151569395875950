<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title>
        <div class="ml-2">
          <router-link class="btn m-l-15 router-link-exact-active router-link-active btn-success" to="add-category"><i
            class="fas fa-plus mr-2"></i>
            Add New
          </router-link>
        </div>
      </bo-page-title>
      <b-card no-body>
        <b-card-header>
          <b-row>
            <b-col lg="5">
              <h5 class="card-title">Kamus Category</h5>
            </b-col>
            <b-col lg="3">
              <b-form-group>
              <v-select placeholer="Select Status" v-model="selected_status" :options="status"></v-select>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-input-group>
                <b-form-input placeholder="Type keyword then enter..."></b-form-input>
                <b-input-group-append>
                  <b-button variant="success"><i class="fas fa-search"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body class="p-0">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Category Name</th>
                <th>Count</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="blog_cat in blog" :key="blog_cat.id">
                <td>{{blog_cat.no}}</td>
                <td>{{blog_cat.cat_name}}</td>
                <td>{{blog_cat.count}}</td>
                <td><span class="badge badge-success">{{blog_cat.status}}</span></td>
                <td>
                  <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                  <a href="#" class="icon_action"><i class="ti-settings"></i></a>
                  <a href="#" class="icon_action"><i class="ti-trash"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>
<script>
    import GlobalVue from '@/libs/Global.vue'
    export default {
    name: 'Article',
    extends: GlobalVue,
    components: {},
    data() {
      return {
        blog: [{
            no: 1,
            cat_name: 'A',
            slug: 'advertorial',
            count: '3',
            status: 'Active'
          },
          {
            no: 2,
            cat_name: 'B',
            slug: 'advertorial',
            count: '10',
            status: 'Active'
          },
          {
            no: 3,
            cat_name: 'C',
            desc: '-',
            slug: 'Bank Digital',
            count: '20',
            status: 'Active'
          },
          {
            no: 4,
            cat_name: 'D',
            slug: 'Bitcoin',
            count: '30',
            status: 'Active'
          },
          {
            no: 5,
            cat_name: 'E',
            slug: 'Blogging Competition',
            count: '40',
            status: 'Active'
          },
          {
            no: 6,
            cat_name: 'F',
            slug: 'emas',
            count: '50',
            status: 'Active'
          }
        ],
        selected: 'Y',
        options: [{
            item: 'Y',
            name: 'Active'
          },
          {
            item: 'N',
            name: 'Inactive'
          }
        ],
        selected_level: 'Administrator',
         levels: [
          'Administrator',
          'Content Writter',
          'Editor'
        ],
        selected_status: 'Active',
        status: [
        'Active',
        'Inactive',]
      }
    }
  }
</script>